<template>
  <div v-style="elementStylesX.columns" class="grid -m-1">
    <div
      v-for="i in maxImagesToDisplay"
      :key="i"
      v-style="elementStylesX.elements[i - 1]"
      :class="{
        'lg:hidden': i > imagesToRenderLg,
        '<lg:hidden': i > imagesToRenderSm,
      }"
    >
      <slot :name="`element-${i - 1}`" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Responsive } from '#types/common'

interface LayoutColumn {
  count: number
  span: number
  spanY?: number
}

const numberOfPassedSlots = Object.keys(useSlots()).length

const columnsSm = 5
const columnsLg = 13

const imagesToRenderLg = [4, 9, 13, 18].find((i, index, array) =>
  (i === numberOfPassedSlots || array[index + 1] > numberOfPassedSlots)) || 18
const imagesToRenderSm = [5, 6, 11].find((i, index, array) =>
  (i === numberOfPassedSlots || array[index + 1] > numberOfPassedSlots)) || 11
const maxImagesToDisplay = Math.max(imagesToRenderLg, imagesToRenderSm)
const layoutSm: LayoutColumn[] = [
  { count: 2, span: 3 },
  { count: 3, span: 2 },
  { count: 1, span: 5 },
  { count: 3, span: 2 },
  { count: 2, span: 3 }
]

const shortLayoutLg: LayoutColumn[] = [
  { count: 1, span: 5, spanY: 6 },
  { count: 2, span: 3 },
  { count: 1, span: 5, spanY: 6 }
]

const longLayoutLg: LayoutColumn[] = [
  { count: 2, span: 3 },
  { count: 3, span: 2 },
  { count: 1, span: 6 },
  { count: 3, span: 2 }
]

const layoutLg = {
  4: shortLayoutLg,
  9: longLayoutLg,
  13: [...longLayoutLg, ...shortLayoutLg],
  18: [...longLayoutLg, ...longLayoutLg.reverse()],
}

const getGrid = (arr: LayoutColumn[], columns: number) => {
  const temp: {
    'grid-col': string
    'grid-row': string
  }[] = []
  let col = 1
  let startRow = 1
  arr.forEach((el) => {
    let row = startRow
    for (let i = 0; i < el.count; i++) {
      const styles = {
        'grid-col': '',
        'grid-row': ''
      }
      const rowSpan = el.spanY || el.span
      styles['grid-col'] = `${col} / span ${el.span}`
      styles['grid-row'] = `${row} / span ${el.spanY || el.span}`
      styles['aspect-ratio'] = `${el.span} / ${rowSpan}`
      temp.push(styles)
      row = row + rowSpan
    }
    col = col + el.span
    if (col > columns) {
      col = 1
      startRow = row
    }
  })
  return { elements: temp, gridRows: startRow - 1 }
}

const elementStylesX = computed(() => {
  const gridLg = getGrid(layoutLg[imagesToRenderLg], columnsLg)
  const gridSm = getGrid(layoutSm, columnsSm)
  const temp = [] as Record<'grid-col' | 'grid-row' | 'aspect-ratio', Responsive>[]
  for (let i = 0; i < maxImagesToDisplay; i++) {
    temp.push({
      'grid-col': {
        sm: gridSm.elements[i]?.['grid-col'],
        lg: gridLg.elements[i]?.['grid-col'],
      },
      'grid-row': {
        sm: gridSm.elements[i]?.['grid-row'],
        lg: gridLg.elements[i]?.['grid-row'],
      },
      'aspect-ratio': {
        sm: '1 / 1',
        lg: gridLg.elements[i]?.['aspect-ratio']
      }
    })
  }
  const columns = {
    'grid-cols': {
      sm: `repeat(${columnsSm}, 1fr)`,
      lg: `repeat(${columnsLg}, 1fr)`
    },
    'grid-rows': {
      sm: `repeat(${gridSm.gridRows}, 1fr)`,
      lg: `repeat(${gridLg.gridRows}, 1fr)`
    }
  }
  return { elements: temp, columns }
})
</script>
